<template>
  <BCard body-class="p-3">
    <BOverlay
      :show="isLoading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BRow class="items-center justify-between mb-2">
        <BCol class="flex items-center">
          <BButton
            variant="primary"
            size="sm"
            class="mr-1"
            @click="$router.go(-1)"
          >
            <feather-icon
              size="1x"
              icon="ChevronLeftIcon"
            />
          </BButton>
          <div class="text-black font-bold m-0 text-2xl">
            Rincian Penarikan Saldo
          </div>
        </BCol>
        <BCol
          lg="4"
          class="flex flex-column contents"
        >
          <div
            v-if="dataWithdrawal.status === 'request' || dataWithdrawal.status === 'review'"
            class="flex"
          >
            <BButton
              id="popoverStatus"
              variant="outline-primary"
            >
              {{ filters.label }}
            </BButton>
            <BPopover
              id="popover-filter-type"
              target="popoverStatus"
              triggers="focus"
              placement="bottom"
            >
              <div
                v-for="(data, index) in chooseAction"
                :key="index+1"
              >
                <BButton
                  v-model="data.value"
                  variant="flat-dark"
                  @click="setActions(data)"
                >
                  {{ data.label }}
                </BButton>
              </div>
            </BPopover>
            <div class="ml-1">
              <BButton
                variant="primary"
                @click="handleActions"
              >
                Terapkan
              </BButton>
            </div>
          </div>
        </BCol>
      </BRow>
      <BRow>
        <BCol lg="11">
          <BRow class="mb-[10px]">
            <BCol
              lg="3"
              class="text-black"
            >
              Waktu Request
            </BCol>
            <BCol lg="9">
              <span class="text-black font-semibold">: {{ DAY_MONTH_YEAR(dataWithdrawal.date) }} ({{ TIME(dataWithdrawal.date) }} WIB)</span>
            </BCol>
          </BRow>
          <BRow class="mb-[10px]">
            <BCol
              lg="3"
              class="text-black"
            >
              Nama Pemilik Bank
            </BCol>
            <BCol lg="9">
              <span class="text-black font-semibold">: {{ dataWithdrawal.bank_owner }}</span>
            </BCol>
          </BRow>
          <BRow class="mb-[10px]">
            <BCol
              lg="3"
              class="text-black"
            >
              Nama Bank
            </BCol>
            <BCol lg="9">
              <span class="text-black font-semibold">: {{ dataWithdrawal.bank_code }}</span>
            </BCol>
          </BRow>
          <BRow class="mb-[10px]">
            <BCol
              lg="3"
              class="text-black"
            >
              No Rekening
            </BCol>
            <BCol
              lg="9"
              class="flex items-center"
            >
              <span class="text-black font-semibold mr-1">: {{ dataWithdrawal.bank_number }}</span>
              <img
                v-b-tooltip.hover="`Copy nomor rekening ${dataWithdrawal.bank_number}`"
                src="https://storage.googleapis.com/komerce/assets/icons/copy.png"
                alt="Komerce"
                class="cursor-pointer"
                @click="toastCopyResi(dataWithdrawal.bank_number)"
              >
            </BCol>
          </BRow>
          <BRow class="mb-[10px]">
            <BCol
              lg="3"
              class="text-black"
            >
              Status Penarikan
            </BCol>
            <BCol lg="9">
              <span
                class="text-black font-semibold p-[5px] rounded"
                :class="classStatus(dataWithdrawal.status)"
              >{{ lableStatus(dataWithdrawal.status) }}</span>
            </BCol>
          </BRow>
          <BRow
            v-if="dataWithdrawal.note !== null"
            class="mb-[10px]"
          >
            <BCol
              lg="3"
              class="text-black"
            >
              Catatan
            </BCol>
            <BCol lg="9">
              <span class="text-black font-semibold">: {{ dataWithdrawal.note }}</span>
            </BCol>
          </BRow>
        </BCol>
      </BRow>
      <BRow class="mt-1">
        <BCol
          class="mb-1"
        >
          <BCard class="balance">
            <div class="mb-1 text-black d-flex">
              <span
                class="k-dollar-square text-[#F95031] text-2xl"
              />
              <span class="ml-1 align-self-center">Saldo Terkini</span>
            </div>
            <BSpinner
              v-if="isLoading"
              variant="primary"
              small
            />
            <span
              v-else
              class="font-semibold text-2xl text-[#F95031]"
            >
              {{ IDR(dataWithdrawal.beginning_balance) }}
            </span>
          </BCard>
        </BCol>
        <BCol
          class="mb-1"
        >
          <BCard class="balance">
            <div class="mb-1 text-black d-flex">
              <span
                class="k-card-receive text-[#34A770] text-2xl"
              />
              <span class="ml-1 align-self-center">Request Penarikan</span>
            </div>
            <BSpinner
              v-if="isLoading"
              variant="primary"
              small
            />
            <span
              v-else
              class="font-semibold text-2xl text-[#34A770]"
            >
              {{ IDR(dataWithdrawal.nominal) }}
            </span>
          </BCard>
        </BCol>
        <BCol
          class="mb-1"
        >
          <BCard class="balance">
            <div class="mb-1 text-black d-flex">
              <span
                class="k-wallet-check text-[#E31A1A] text-2xl"
              />
              <span class="ml-1 align-self-center">Saldo Jika Disetujui</span>
            </div>
            <BSpinner
              v-if="isLoading"
              variant="primary"
              small
            />
            <span
              v-else
              class="font-semibold text-2xl text-[#08A0F7]"
            >
              {{ IDR(dataWithdrawal.ending_balance) }}
            </span>
          </BCard>
        </BCol>
      </BRow>
    </BOverlay>

    <ModalReviewReject
      :get-detail-withdrawal="getDetailWithdrawal"
      :data-user-child="dataUser"
    />
  </BCard>
</template>

<script>
import { collaboratorAxiosIns } from '@/libs/axios'
import { DAY_MONTH_YEAR, TIME } from '@/libs/filterDate'
import { IDR } from '@/libs/currency'
import isEmpty from 'lodash/isEmpty'
import ModalReviewReject from './ModalReviewReject.vue'
import { listDetailWithdrawal, chooseAction } from './config'

export default {
  components: { ModalReviewReject },
  data() {
    return {
      isLoadingTable: false,
      isLoading: false,
      dataWithdrawal: {},
      filters: {
        label: 'Pilih Tindakan',
        value: '',
      },
      chooseAction,
      items: [],
      fields: listDetailWithdrawal,
      DAY_MONTH_YEAR,
      TIME,
      IDR,
      currentPage: 1,
      totalRows: 0,
      optionsPage: [20, 50, 100, 200, 400],
      totalPerPage: 20,
      isEmpty,
      isNotesNotification: '',
      dataUser: {
        user_id: this.$route.params.user_id,
        id: this.$route.params.id,
        title: '',
      },
    }
  },
  watch: {
    currentPage: {
      handler() {
        this.getDetailWithdrawal()
      },
    },
  },
  mounted() {
    if (this.dataUser.user_id === undefined) {
      this.$router.go(-1)
    } else {
      this.getDetailWithdrawal()
    }
  },
  methods: {
    async getDetailWithdrawal() {
      this.isLoading = true
      const url = `/user/api/v1/admin/users/${this.dataUser.user_id}/withdrawals/${this.dataUser.id}`
      await collaboratorAxiosIns.get(url)
        .then(async res => {
          const { data } = res.data
          this.dataWithdrawal = data
          this.isLoading = false
        })
        .catch(err => {
          this.$toast_error({ message: 'Gagal load data', timeout: 1000 })
          this.isLoading = false
        })
    },
    setPerPage(page) {
      this.totalPerPage = page
      this.getDetailWithdrawal()
    },
    setActions(data) {
      this.filters = data
      this.$root.$emit('bv::hide::popover', 'popover-filter-type')
    },
    async handleActions() {
      this.dataUser = {
        ...this.dataUser,
        note: this.dataWithdrawal.note === null ? '' : this.dataWithdrawal.note,
      }
      if (this.filters.value === 'review') {
        this.dataUser = {
          ...this.dataUser,
          title: 'Buat catatan penarikan',
          button: 'Review & Submit',
        }
        await this.$bvModal.show('modal-review-reject')
      }
      if (this.filters.value === 'rejected') {
        this.dataUser = {
          ...this.dataUser,
          title: 'Buat catatan penolakan',
          button: 'Tolak & Submit',
        }
        await this.$bvModal.show('modal-review-reject')
      }
      if (this.filters.value === 'approved') {
        await this.$router.push({ name: 'withdrawal-collaborator-transfer', params: this.dataUser })
      }
    },
    toastCopyResi(number) {
      navigator.clipboard.writeText(number)
      this.$toast_success({ message: `${number} berhasil disalin`, timeout: 1000 })
    },
    lableStatus(value) {
      if (value === 'request') return 'Request Penarikan'
      if (value === 'review') return 'Sedang Direview'
      if (value === 'approved') return 'Disetujui'
      if (value === 'rejected') return 'Ditolak'
      return ''
    },
    classStatus(value) {
      if (value === 'request') return 'requestedList'
      if (value === 'review') return 'on_reviewList'
      if (value === 'approved') return 'completedList'
      if (value === 'rejected') return 'rejectedList'
      return ''
    },
  },
}
</script>

<style scoped lang="scss">
@import '../affiliate/Withdrawal.scss';
</style>
