/* eslint-disable import/prefer-default-export */
import { IDR } from '@/libs/currency'
import {
  LABELDAY, LABELMONTH, YEAR_MONTH_DAY, TIME, DAY_MONTH_YEAR,
} from '@/libs/filterDate'

export const chooseAction = [
  {
    label: 'Transfer Manual',
    value: 'approved',
  },
  {
    label: 'Review',
    value: 'review',
  },
  {
    label: 'Tolak',
    value: 'rejected',
  },
]

export const listDetailWithdrawal = [
  {
    key: 'timestamp',
    label: 'Tanggal',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'mutation_description',
    label: 'Uraian Mutasi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'nominal',
    label: 'Jumlah Nominal',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'saldo',
    label: 'Saldo Akhir',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
]

export const listWithdrawal = [
  {
    key: 'date',
    label: 'Tanggal',
    class: 'border-b-0 text-black p-1 text-start',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
  },
  {
    key: 'bank_owner',
    label: 'Nama',
    class: 'border-b-0 text-black p-1 text-start',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '100px',
    },
  },
  {
    key: 'type',
    label: 'Tipe',
    class: 'border-b-0 text-black p-1 text-start',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '100px',
    },
  },
  {
    key: 'bank_code',
    label: 'Nama Bank',
    class: 'border-b-0 text-black p-1 text-start',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '100px',
    },
  },
  {
    key: 'bank_number',
    label: 'No Rekening',
    class: 'border-b-0 text-black p-1 text-start',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '120px',
    },
  },
  {
    key: 'nominal',
    label: 'Nominal',
    class: 'border-b-0 text-black p-1 text-start',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '120px',
    },
    formatter: value => IDR(value),
  },
  {
    key: 'status',
    label: 'Status',
    class: 'border-b-0 text-black p-1 text-start',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '150px',
    },
  },
]

export const statusOptionsListWithdrawal = [
  {
    value: null,
    text: 'Pilih Status',
    disabled: true,
  },
  {
    value: '',
    text: 'Semua',
  },
  {
    value: 'request',
    text: 'Request Penarikan',
  },
  {
    value: 'review',
    text: 'Sedang Direview',
  },
  {
    value: 'approved',
    text: 'Disetujui',
  },
  {
    value: 'rejected',
    text: 'Ditolak',
  },
]
